import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom'

//#region Imports Table
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
//import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { red } from '@material-ui/core/colors';
//#endregion

import '../../Global.css'
import logo from '../../assets/logo.png'
import './styless.css'

import api from '../../services/api'


const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: red[800],
      color: theme.palette.common.white,
      fontSize: 12,
    },
    body: {
      fontSize: 12,
    },
  }))(TableCell);

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));
  
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };
  
  function createData(id_p, pergunta, cod, r1, r2, r3, r4, r5, r6) {
    return { id_p, pergunta, cod, r1, r2, r3, r4, r5, r6 };
  }

  function createDataResp(pergunta, respota) {
    return { pergunta, respota };
  }
  
  const rows = []
  const resp =[]
  
  const useStyles2 = makeStyles({
    table: {
      minWidth: 500,
    },
    container:{
      backgroundColor: 'white',
    }
  });
  

export default function Survey(){
    const classes = useStyles2();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(59);
    //const [selectedValue, setSelectedValue] = React.useState('');
    //const [linha, setLinha] = React.useState('');
    const [desc, setDesc] = useState('')
    const [classe, setClasse] = useState('')
    const userNome = localStorage.getItem('userNome')
    const userId = localStorage.getItem('userId')
    const userPesquisaAvulsa = localStorage.getItem('userPesquisaAvulsa')
    const userClasseAcesso = localStorage.getItem('userClasseAcesso')
    //const surveyId = localStorage.getItem('surveyId')
    
    async function handleSurvey(e){
        e.preventDefault()
        let retorno = "Necessário preenchimento de todos os dados do formulário. "
        if (resp.length < 59){
          let questoes = "\n\nQuestões não respondidas: "
          for (let i = 1; i < 60; i++) {
            if (!resp.find(x=>x.pergunta == i)){
              questoes = questoes.concat(i).concat(',')
            }
          }
          retorno = retorno.concat(questoes)

          alert(retorno)
        } else if (userClasseAcesso == 2 && classe == ""){
          retorno = retorno.concat("\n\nÉ necessário selecionar a classe que você pertence.")
          alert(retorno)
        } else {
          //console.log(resp)
          await submitSurvey(resp)
          history.push('/pesquisa/respondida')
        }
    }

    async function submitSurvey(resp){
      try {
        console.log(resp)
        resp.forEach(async e => {
          const dt = new Date()
          await api.post('SendSurvey',{
            user_id: userId,
            survey_id: e.pergunta,
            resposta: e.respota,
            date: dt.getDate() + "/" + (dt.getMonth()+1) + "/" + dt.getFullYear(),
            nome_entrevistado: "",
            classe
          })
        });

      } catch (error) {
        alert("Erro ao tentar salvar as informações. Por favor tente novamente.")
      }
    }

    function radioChange(prop){
      let c = false
      if (resp.length == 0){
        resp.push(createDataResp(prop.id,prop.value))
      } else {
        for (var i in resp) {
          if (resp[i].pergunta == prop.id) {
              resp[i].respota = prop.value;
              c = true
             break; //Stop this loop, we found it!
          }
        }

        if (!c){
          resp.push(createDataResp(prop.id,prop.value))
        }
        
        //resp.push(createDataResp(prop.name,prop.value))
      }
        //console.log(resp)
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
/*
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };*/

    const rowStyle = {
      'maxWidth': '500px'
    }
    
    const history = useHistory()
    function Logout(){
      localStorage.clear()

      history.push('/')
    }

    const [questoes, setQuestoes] = useState([])
    useEffect(() => {
      api.get('Survey?questionario=1', {
        
      }).then(resp => {
        console.log(resp)
        resp.data.map(function(x){
          let pergunta = ""
          if (x.numero_pergunta < 60){
            pergunta = pergunta.concat(x.numero_pergunta).concat(' - ').concat(x.pergunta)
            rows.push(createData(x._id, pergunta, x.numero_pergunta, 1, 2, 3, 4, 5, 6))
          }
        })
        setQuestoes(resp.data)
      })
    }, [])

    return(
        <div className="survey-container">
          
            <div className="actions">
            {( userPesquisaAvulsa == 'true' ? <Link className="back-link" to="/pesquisa/avulsa">Pesquisa Avulsa</Link> : ""                     
                  )}
            </div>
            <div className="survey-content"> 
            
            <form onSubmit={handleSurvey}>
                { (userClasseAcesso == 2 ? 
                  <div className="selecao-classe">
                    <input className="radioClasse" id="1" type="radio" value="CLAM"  name="CLAM" onChange={() => setClasse('CLAM')} /><label for="1">CLAM</label>
                    <input className="radioClasse" id="2" type="radio" value="CONCÍLIO" name="CLAM" onChange={() => setClasse('CONCÍLIO')} /><label for="2">CONCÍLIO</label>
                  </div>
                : ""
                  
                  )}
                
                <TableContainer className={classes.container}>
                    <Table stickyHeader  className={classes.table} aria-label="custom pagination table">
                        <TableHead className="cabecario-pesquisa">
                            <TableRow>
                                <StyledTableCell>Perguntas</StyledTableCell>
                                <StyledTableCell align="center">Concordo Totalmente</StyledTableCell>
                                <StyledTableCell align="center">Concordo</StyledTableCell>
                                <StyledTableCell align="center">As vezes concordo as vezes discordo</StyledTableCell>
                                <StyledTableCell align="center">Discordo</StyledTableCell>
                                <StyledTableCell align="center">Discordo Totalmente</StyledTableCell>
                                <StyledTableCell align="center">Desconheço o Assunto</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {(rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row) => (
                            <TableRow key={row.cod}>
                                
                            <TableCell style={rowStyle} component="th" scope="row">
                                {row.pergunta}
                            </TableCell>
                            <TableCell align="center">
                                <input className="radioresposta" id={row.id_p} type="radio" value={row.r1} onChange={e => radioChange(e.target)} name={row.cod} />
                            </TableCell>
                            <TableCell align="center">
                                <input className="radioresposta" id={row.id_p} type="radio" value={row.r2} onChange={e => radioChange(e.target)} name={row.cod} />
                            </TableCell>
                            <TableCell align="center">
                                <input className="radioresposta" id={row.id_p} type="radio" value={row.r3} onChange={e => radioChange(e.target)} name={row.cod} />
                            </TableCell>
                            <TableCell align="center">
                                <input className="radioresposta" id={row.id_p} type="radio" value={row.r4} onChange={e => radioChange(e.target)} name={row.cod} />
                            </TableCell>
                            <TableCell align="center">
                                <input className="radioresposta" id={row.id_p} type="radio" value={row.r5} onChange={e => radioChange(e.target)} name={row.cod} />
                            </TableCell>
                            <TableCell align="center">
                                <input className="radioresposta" id={row.id_p} type="radio" value={row.r6} onChange={e => radioChange(e.target)} name={row.cod} />
                            </TableCell>
                            </TableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        </TableBody>
                        <TableFooter>
                        {/*<TableRow>
                            <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                            colSpan={3}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>*/}
                        </TableFooter>
                    </Table>
                    </TableContainer>
                    <div className="content-desc">
                      <label>Descreva suas observações e sugestões (Opcional). [Máximo 600 caracteres]</label>
                      <textarea maxlength="600" type="textarea" value={desc} onChange={e=>setDesc(e.target.value)} className="textArea"  />
                    </div>
                    <div className="footer-button">
                        <button className="btn" type="submit">Enviar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}