import React, { useEffect } from "react";
import ReactExport from "react-export-excel";
import { Link } from 'react-router-dom'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export class DownloadPendentes extends React.Component {
    render() {
        return (
            <ExcelFile filename="Pesquisas Pendentes" element={<Link to="#" className="link-actions">{this.props.name}</Link>}>
                <ExcelSheet data={this.props.dataSet1} name="1ª">
                    <ExcelColumn label="Classe" value="classe"/>
                    <ExcelColumn label="Nome" value="nome"/>
                    <ExcelColumn label="E-mail" value="email"/>
                    {/*<ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>*/}
                </ExcelSheet>
                <ExcelSheet data={this.props.dataSet1} name="2ª Região">
                    <ExcelColumn label="Classe" value="classe"/>
                    <ExcelColumn label="Nome" value="nome"/>
                    <ExcelColumn label="E-mail" value="email"/>
                    {/*<ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>*/}
                </ExcelSheet>
                <ExcelSheet data={this.props.dataSet1} name="3ª Região">
                    <ExcelColumn label="Classe" value="classe"/>
                    <ExcelColumn label="Nome" value="nome"/>
                    <ExcelColumn label="E-mail" value="email"/>
                    {/*<ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>*/}
                </ExcelSheet>
                <ExcelSheet data={this.props.dataSet1} name="4ª Região">
                    <ExcelColumn label="Classe" value="classe"/>
                    <ExcelColumn label="Nome" value="nome"/>
                    <ExcelColumn label="E-mail" value="email"/>
                    {/*<ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>*/}
                </ExcelSheet>
                <ExcelSheet data={this.props.dataSet1} name="5ª Região">
                    <ExcelColumn label="Classe" value="classe"/>
                    <ExcelColumn label="Nome" value="nome"/>
                    <ExcelColumn label="E-mail" value="email"/>
                    {/*<ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>*/}
                </ExcelSheet>
                <ExcelSheet data={this.props.dataSet1} name="6ª Região">
                    <ExcelColumn label="Classe" value="classe"/>
                    <ExcelColumn label="Nome" value="nome"/>
                    <ExcelColumn label="E-mail" value="email"/>
                    {/*<ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>*/}
                </ExcelSheet>
                <ExcelSheet data={this.props.dataSet1} name="7ª Região">
                    <ExcelColumn label="Classe" value="classe"/>
                    <ExcelColumn label="Nome" value="nome"/>
                    <ExcelColumn label="E-mail" value="email"/>
                    {/*<ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>*/}
                </ExcelSheet>
                <ExcelSheet data={this.props.dataSet1} name="8ª Região">
                    <ExcelColumn label="Classe" value="classe"/>
                    <ExcelColumn label="Nome" value="nome"/>
                    <ExcelColumn label="E-mail" value="email"/>
                    {/*<ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>*/}
                </ExcelSheet>
                <ExcelSheet data={this.props.dataSet1} name="REMNE">
                    <ExcelColumn label="Classe" value="classe"/>
                    <ExcelColumn label="Nome" value="nome"/>
                    <ExcelColumn label="E-mail" value="email"/>
                    {/*<ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>*/}
                </ExcelSheet>
                <ExcelSheet data={this.props.dataSet1} name="REMA">
                    <ExcelColumn label="Classe" value="classe"/>
                    <ExcelColumn label="Nome" value="nome"/>
                    <ExcelColumn label="E-mail" value="email"/>
                    {/*<ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>*/}
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export class DownloadPesquisasTotais extends React.Component {
    render() {
        return (
            <ExcelFile filename="Pesquisas Respondidas" element={<Link to="#" className="link-actions">{this.props.name}</Link>}>
                <ExcelSheet data={this.props.dataSetTotais} name="Totais">
                    <ExcelColumn label="Região" value="regiao"/>
                    <ExcelColumn label="Concordo Totalmente" value="concordatotal"/>
                    <ExcelColumn label="Concordo" value="concordo"/>
                    <ExcelColumn label="As vezes concordo as vezes discordo" value="concordodiscordo"/>
                    <ExcelColumn label="Discordo" value="discordo"/>
                    <ExcelColumn label="Discordo Totalmente" value="discordototal"/>
                    <ExcelColumn label="Desconheço o Assunto" value="desconhecoassunto"/>
                    {/*<ExcelColumn label="Marital Status"
                                 value={(col) => col.is_married ? "Married" : "Single"}/>*/}
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export class DownloadPesquisasClasse extends React.Component {
    render() {
        return (
            <ExcelFile filename="Pesquisas Respondidas - Por Classe" element={<Link to="#" className="link-actions">{this.props.name}</Link>}>
                <ExcelSheet data={this.props.dataSetCasse1} name="Pastores(as)">
                    <ExcelColumn label="Região" value="regiao"/>
                    <ExcelColumn label="Concordo Totalmente" value="concordatotal"/>
                    <ExcelColumn label="Concordo" value="concordo"/>
                    <ExcelColumn label="As vezes concordo as vezes discordo" value="concordodiscordo"/>
                    <ExcelColumn label="Discordo" value="discordo"/>
                    <ExcelColumn label="Discordo Totalmente" value="discordototal"/>
                    <ExcelColumn label="Desconheço o Assunto" value="desconhecoassunto"/>
                </ExcelSheet>
                <ExcelSheet data={this.props.dataSetCasse2} name="CLAM">
                    <ExcelColumn label="Região" value="regiao"/>
                    <ExcelColumn label="Concordo Totalmente" value="concordatotal"/>
                    <ExcelColumn label="Concordo" value="concordo"/>
                    <ExcelColumn label="As vezes concordo as vezes discordo" value="concordodiscordo"/>
                    <ExcelColumn label="Discordo" value="discordo"/>
                    <ExcelColumn label="Discordo Totalmente" value="discordototal"/>
                    <ExcelColumn label="Desconheço o Assunto" value="desconhecoassunto"/>
                </ExcelSheet>
                <ExcelSheet data={this.props.dataSetCasse2C} name="CONCÍLIO">
                    <ExcelColumn label="Região" value="regiao"/>
                    <ExcelColumn label="Concordo Totalmente" value="concordatotal"/>
                    <ExcelColumn label="Concordo" value="concordo"/>
                    <ExcelColumn label="As vezes concordo as vezes discordo" value="concordodiscordo"/>
                    <ExcelColumn label="Discordo" value="discordo"/>
                    <ExcelColumn label="Discordo Totalmente" value="discordototal"/>
                    <ExcelColumn label="Desconheço o Assunto" value="desconhecoassunto"/>
                </ExcelSheet>
                <ExcelSheet data={this.props.dataSetCasse4} name="Grupo de Discipulado">
                    <ExcelColumn label="Região" value="regiao"/>
                    <ExcelColumn label="Concordo Totalmente" value="concordatotal"/>
                    <ExcelColumn label="Concordo" value="concordo"/>
                    <ExcelColumn label="As vezes concordo as vezes discordo" value="concordodiscordo"/>
                    <ExcelColumn label="Discordo" value="discordo"/>
                    <ExcelColumn label="Discordo Totalmente" value="discordototal"/>
                    <ExcelColumn label="Desconheço o Assunto" value="desconhecoassunto"/>
                </ExcelSheet>
                <ExcelSheet data={this.props.dataSetCasse3} name="Seguimento Nacionais">
                    <ExcelColumn label="Região" value="regiao"/>
                    <ExcelColumn label="Concordo Totalmente" value="concordatotal"/>
                    <ExcelColumn label="Concordo" value="concordo"/>
                    <ExcelColumn label="As vezes concordo as vezes discordo" value="concordodiscordo"/>
                    <ExcelColumn label="Discordo" value="discordo"/>
                    <ExcelColumn label="Discordo Totalmente" value="discordototal"/>
                    <ExcelColumn label="Desconheço o Assunto" value="desconhecoassunto"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}

export class DownloadSugestoes extends React.Component {
    render() {
        return (
            <ExcelFile filename="Sugestões da Pesquisa" element={<Link to="#" className="link-actions">{this.props.name}</Link>}>
                <ExcelSheet data={this.props.dataSetSuguestoes} name="Sugestões">
                    <ExcelColumn label="Nome" value="nome"/>
                    <ExcelColumn label="Sugestão" value="sugestao"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}