import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types';
import '../../Global.css'
import './styless.css'

//#region Imports Table
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TableHead from '@material-ui/core/TableHead';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { red } from '@material-ui/core/colors';
//#endregion

import logo from '../../assets/logo.png'

import api from '../../services/api'

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: red[800],
      color: theme.palette.common.white,
      fontSize: 12,
    },
    body: {
      fontSize: 12,
    },
  }))(TableCell);

const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));
  
  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
  
  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  function createDataResp(cod, pergunta, resposta) {
    return { cod, pergunta, resposta };
  }
  
  let rows = []
  
  const useStyles2 = makeStyles({
    table: {
      minWidth: 500,
    },
  });

  const opcoes = {
    1: 'Concordo Totalmente',
    2: 'Concordo',
    3: 'As vezes concordo as vezes discordo',
    4: 'Discordo',
    5: 'Discordo Totalmente',
    6: 'Desconheço o Assunto'
}

export default function ResponseSurvey() {
    const userNome = localStorage.getItem('userNome')
    const history = useHistory()
    const classes = useStyles2();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(59);
    const [respostas, setRespostas] = useState([])
    const userId = localStorage.getItem('userId')
    const [ro, setRo] = useState([])
    const userPesquisaAvulsa = localStorage.getItem('userPesquisaAvulsa')
    

    const rowStyle = {
      'maxWidth': '500px'
    }
    useEffect(() => {
      api.post('UserSurvey', {
            user_id: userId
      }).then(resp => {
        rows = []
        resp.data.forEach(e => {
            let pergunta = ""
            let opResp = ""
            switch (e.resposta) {
                case "1":
                    opResp = opcoes[1]
                    break;
                case "2":
                    opResp = opcoes[2]
                    break;
                case "3":
                    opResp = opcoes[3]
                    break;
                case "4":
                    opResp = opcoes[4]
                    break;
                case "5":
                    opResp = opcoes[5]
                break;
                case "6":
                    opResp = opcoes[6]
                break;
                default:
                    opResp = "";
                    break;
            }
            pergunta = pergunta.concat(e.survey.numero_pergunta).concat(' - ').concat(e.survey.pergunta)

            rows.push(createDataResp(e.survey.numero_pergunta, pergunta, opResp))
        });
        rows.sort((a, b) => (a.cod < b.cod ? -1 : 1))
        setRo(rows)
        setRespostas(resp.data)
      })
    }, [userId])

    function Logout(){
        localStorage.clear()

        history.push('/')
    }

    return (
        <div className="resp-survey-container">
            <header>
                <img src={logo} alt="Igreja Metodista" />
                <span>Olá {userNome}</span>
                <button onClick={Logout} type="button">
                    <PowerSettingsNewIcon className="power-icon" />    
                </button>
                
            </header>
            <div className="actions">
            {( userPesquisaAvulsa == 'true' ? <Link className="back-link" to="/pesquisa/avulsa">Pesquisa Avulsa</Link> : ""                     
                  )}
            </div>
            <div className="resp-survey-content">
                <p>
                    Pesquisa já respondida. Obrigado.
                </p>
                <Table className={classes.table} aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Perguntas</StyledTableCell>
                                <StyledTableCell align="center">Resposta</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {(rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row) => (
                            <TableRow key={row.cod}>
                                
                            <TableCell style={rowStyle} component="th" scope="row">
                                {row.pergunta}
                            </TableCell>
                            <TableCell align="center">
                                <label>{row.resposta}</label>
                                
                            </TableCell>
                            </TableRow>
                        ))
                        }
                        </TableBody>
                        <TableFooter>
                        </TableFooter>
                    </Table>
            </div>
        </div>
    );
}
