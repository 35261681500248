import React, { useState, useEffect } from 'react'
import { useHistory, Link } from 'react-router-dom'
import '../../Global.css'
import './styless.css'
import ReactExport from "react-export-excel";
import { DownloadPendentes, DownloadSugestoes, DownloadPesquisasClasse, DownloadPesquisasTotais } from "../../Components/Download"


import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

import logo from '../../assets/logo.png'

import api from '../../services/api'

let dataSet1 = []

let dataSetTotais = []

let dataSetCasse1 = []
let dataSetCasse2 = []
let dataSetCasse2C = []
let dataSetCasse3 = []
let dataSetCasse4 = []

let dataSetSuguestoes = []

export default function App() {
    const userNome = localStorage.getItem('userNome')
    const userId = localStorage.getItem('userId')
    const [dashboard, setDashboard] = useState([]);
    const [dashboardUsers, setDashboardUsers] = useState();
    const history = useHistory()
    let contatador = 0
    let saldoResposta = 0
    let saldoRegiao1 = 0
    let saldoRegiao2 = 0
    let saldoRegiao3 = 0
    let saldoRegiao4 = 0
    let saldoRegiao5 = 0
    let saldoRegiao6 = 0
    let saldoRegiao7 = 0
    let saldoRegiao8 = 0
    let saldoRegiaoRemne = 0
    let saldoRegiaoRema = 0

    let totalRegiao1 = 0
    let totalRegiao2 = 0
    let totalRegiao3 = 0
    let totalRegiao4 = 0
    let totalRegiao5 = 0
    let totalRegiao6 = 0
    let totalRegiao7 = 0
    let totalRegiao8 = 0
    let totalRegiaoRemne = 0
    let totalRegiaoRema = 0
    
    
    useEffect(() => {
        api.get('Dashboard?id=1',{}).then(resp => {
            setDashboardUsers(resp.data)
        })
      }, [userId])

      useEffect(() => {
        api.get('Excel?id=1',{}).then(resp => {
          dataSet1 = resp.data
        })
      }, [userId])

      useEffect(() => {
        api.get('Excel?id=2',{}).then(resp => {
          dataSetTotais = resp.data
        })
      }, [userId])

      useEffect(() => {
        api.get('Excel?id=3',{}).then(resp => {
          dataSetCasse1 = resp.data.classe_1
          dataSetCasse2 = resp.data.classe_2_CLAM
          dataSetCasse2C = resp.data.classe_2_CONCILIO
          dataSetCasse3 = resp.data.classe_3
          dataSetCasse4 = resp.data.classe_4
        })
      }, [userId])

      useEffect(() => {
        api.get('Excel?id=4',{}).then(resp => {
          dataSetSuguestoes = resp.data
        })
      }, [userId])

      useEffect(() => {
        api.get('Dashboard?id=2',{}).then(resp => {
            setDashboard(resp.data)
        })
      }, [userId])
      for (var prop in dashboard){
        switch (prop) {
            case "userRegiao1":
                saldoRegiao1 = dashboard[prop]
                break;
            case "userRegiao2":
                saldoRegiao2 = dashboard[prop]
                break;
            case "userRegiao3":
                saldoRegiao3 = dashboard[prop]
                break;
            case "userRegiao4":
                saldoRegiao4 = dashboard[prop]
                break;
            case "userRegiao5":
                saldoRegiao5 = dashboard[prop]
                break;
            case "userRegiao6":
                saldoRegiao6 = dashboard[prop]
                break;
            case "userRegiao7":
                saldoRegiao7 = dashboard[prop]
                break;
            case "userRegiao8":
                saldoRegiao8 = dashboard[prop]
                break;
            case "userRegiaoREMMNE":
                saldoRegiaoRemne = dashboard[prop]
                break;
            case "userRegiaoREMA":
                saldoRegiaoRema = dashboard[prop]
                break;
        
            default:
                break;
        }
      }

    for (var prop in dashboardUsers){
        switch (prop) {
            case "userRegiao1":
                totalRegiao1 = dashboardUsers[prop]
                break;
            case "userRegiao2":
                totalRegiao2 = dashboardUsers[prop]
                break;
            case "userRegiao3":
                totalRegiao3 = dashboardUsers[prop]
                break;
            case "userRegiao4":
                totalRegiao4 = dashboardUsers[prop]
                break;
            case "userRegiao5":
                totalRegiao5 = dashboardUsers[prop]
                break;
            case "userRegiao6":
                totalRegiao6 = dashboardUsers[prop]
                break;
            case "userRegiao7":
                totalRegiao7 = dashboardUsers[prop]
                break;
            case "userRegiao8":
                totalRegiao8 = dashboardUsers[prop]
                break;
            case "userRegiaoREMMNE":
                totalRegiaoRemne = dashboardUsers[prop]
                break;
            case "userRegiaoREMA":
                totalRegiaoRema = dashboardUsers[prop]
                break;
        
            default:
                break;
        }


    }
    function Logout(){
        localStorage.clear()
    
        history.push('/')
    }
  return (
      
    <div className="dashboard-container">
        <header>
            <img src={logo} alt="Igreja Metodista" />
            <span>Olá {userNome}</span>
            <button onClick={Logout} type="button">
                <PowerSettingsNewIcon className="power-icon" />    
            </button>
        </header>
      <div className="dashboard-content">
      <div className="menu-actions">
          <div className="card-menu card-menu-stats">
            <div className="card-menu-header card-menu-header-warning card-menu-header-icon">
                <div className="card-menu-icon">
                <i className="material-icons">MENU</i>
                </div>
                <h3 className="card-menu-title">Relatório</h3>
                    <DownloadPendentes name="Pendentes" dataSet1={dataSet1} ></DownloadPendentes>
                    <DownloadPesquisasTotais name="Respondidas Totais" dataSetTotais={dataSetTotais} ></DownloadPesquisasTotais>
                    <DownloadPesquisasClasse name="Respondidas Por Classe" dataSetCasse1={dataSetCasse1} dataSetCasse2={dataSetCasse2} dataSetCasse2C={dataSetCasse2C} dataSetCasse3={dataSetCasse3} dataSetCasse4={dataSetCasse4} ></DownloadPesquisasClasse>
                    <DownloadSugestoes name="Relação das Sugestões" dataSetSuguestoes={dataSetSuguestoes} ></DownloadSugestoes>
                <h3 className="card-menu-title">Ações</h3>
                    <Link className="link-actions" to="/parametros">Liberar Pesquisa Avulsa</Link>
            </div>
           </div>
          </div>

          <div className="header-card-view">
            <div className="card card-stats">
                <div className="card-header card-header-warning card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">1ª Região</i>
                  </div>
                  <h3 className="card-title">{saldoRegiao1}/{totalRegiao1}
                  </h3>
                </div>
                <div className="card-footer">
                  <div className="stats">
                      {
                          saldoRegiao1 >= totalRegiao1 ?
                            <span className="concluido">Concluído</span>
                          :
                            <span className="pendente">Pendente</span>
                      }
                  </div>
                </div>
              </div>
              <div className="card card-stats">
                <div className="card-header card-header-warning card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">2ª Região</i>
                  </div>
                  <h3 className="card-title">{saldoRegiao2}/{totalRegiao2}
                  </h3>
                </div>
                <div className="card-footer">
                  <div className="stats">
                  {
                          saldoRegiao2 == totalRegiao2 ?
                            <span className="concluido">Concluído</span>
                          :
                            <span className="pendente">Pendente</span>
                      }
                  </div>
                </div>
              </div>
              <div className="card card-stats">
                <div className="card-header card-header-warning card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">3ª Região</i>
                  </div>
                  <h3 className="card-title">{saldoRegiao3}/{totalRegiao3}
                  </h3>
                </div>
                <div className="card-footer">
                  <div className="stats">
                  {
                          saldoRegiao3 == totalRegiao3 ?
                            <span className="concluido">Concluído</span>
                          :
                            <span className="pendente">Pendente</span>
                      }
                  </div>
                </div>
              </div>
              <div className="card card-stats">
                <div className="card-header card-header-warning card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">4ª Região</i>
                  </div>
                  <h3 className="card-title">{saldoRegiao4}/{totalRegiao4}
                  </h3>
                </div>
                <div className="card-footer">
                  <div className="stats">
                  {
                          saldoRegiao4 == totalRegiao4 ?
                            <span className="concluido">Concluído</span>
                          :
                            <span className="pendente">Pendente</span>
                      }
                  </div>
                </div>
              </div>
              <div className="card card-stats">
                <div className="card-header card-header-warning card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">5ª Região</i>
                  </div>
                  <h3 className="card-title">{saldoRegiao5}/{totalRegiao5}
                  </h3>
                </div>
                <div className="card-footer">
                  <div className="stats">
                  {
                          saldoRegiao5 == totalRegiao5 ?
                            <span className="concluido">Concluído</span>
                          :
                            <span className="pendente">Pendente</span>
                      }
                  </div>
                </div>
              </div>
              <div className="card card-stats">
                <div className="card-header card-header-warning card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">6ª Região</i>
                  </div>
                  <h3 className="card-title">{saldoRegiao6}/{totalRegiao6}
                  </h3>
                </div>
                <div className="card-footer">
                  <div className="stats">
                  {
                          saldoRegiao6 == totalRegiao6 ?
                            <span className="concluido">Concluído</span>
                          :
                            <span className="pendente">Pendente</span>
                      }
                  </div>
                </div>
              </div>
              <div className="card card-stats">
                <div className="card-header card-header-warning card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">7ª Região</i>
                  </div>
                  <h3 className="card-title">{saldoRegiao7}/{totalRegiao7}
                  </h3>
                </div>
                <div className="card-footer">
                  <div className="stats">
                  {
                          saldoRegiao7 == totalRegiao7 ?
                            <span className="concluido">Concluído</span>
                          :
                            <span className="pendente">Pendente</span>
                      }
                  </div>
                </div>
              </div>
              <div className="card card-stats">
                <div className="card-header card-header-warning card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">8ª Região</i>
                  </div>
                  <h3 className="card-title">{saldoRegiao8}/{totalRegiao8}
                  </h3>
                </div>
                <div className="card-footer">
                  <div className="stats">
                  {
                          saldoRegiao8 == totalRegiao8 ?
                            <span className="concluido">Concluído</span>
                          :
                            <span className="pendente">Pendente</span>
                      }
                  </div>
                </div>
              </div>
              <div className="card card-stats">
                <div className="card-header card-header-warning card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">REMNE</i>
                  </div>
                  <h3 className="card-title">{saldoRegiaoRemne}/{totalRegiaoRemne}
                  </h3>
                </div>
                <div className="card-footer">
                  <div className="stats">
                  {
                          saldoRegiaoRemne == totalRegiaoRemne ?
                            <span className="concluido">Concluído</span>
                          :
                            <span className="pendente">Pendente</span>
                      }
                  </div>
                </div>
              </div>
              <div className="card card-stats">
                <div className="card-header card-header-warning card-header-icon">
                  <div className="card-icon">
                    <i className="material-icons">REMA</i>
                  </div>
                  <h3 className="card-title">{saldoRegiaoRema}/{totalRegiaoRema}
                  </h3>
                </div>
                <div className="card-footer">
                  <div className="stats">
                  {
                          saldoRegiaoRema == totalRegiaoRema ?
                            <span className="concluido">Concluído</span>
                          :
                            <span className="pendente">Pendente</span>
                      }
                  </div>
                </div>
              </div>
          </div>
          
          {/*
          <div className="menu-actions">
            <Link className="link-actions" to="/pesquisa/avulsa">Pesquisa Avulsa</Link>
            <Link className="link-actions" to="/pesquisa/avulsa">Enviar E-mail</Link>
            <Link className="link-actions" to="/pesquisa/avulsa">Entrair Relatório</Link>
          </div>
          */}
      </div>
    </div>
  );
}
