import React, { useEffect, useState } from 'react'
import { Checkbox } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom'
import PropTypes from 'prop-types';
import '../../Global.css'
import './styless.css'

import logo from '../../assets/logo.png'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import api from '../../services/api'

export default function Parameters() {
    const userNome = localStorage.getItem('userNome')
    const userId = localStorage.getItem('userId')
    const history = useHistory()
    const [parameter, setParameter] = useState([]);

    useEffect(() => {
        api.get('Parameter?id=2',{}).then(resp => {
            setParameter(resp.data)
        })
      }, [userId])
      console.log(parameter)
    function checkChange(prop){
        console.log(prop.value)
        parameter.map(x=>{
                if (x.id === prop.id){
                    x.pesquisa_avulsa = !x.pesquisa_avulsa
                }
                return x
        })
        
        setParameter(parameter)
        console.log(parameter)
    }

    async function handleParameter(e){
        e.preventDefault()
        var retorno = []
        parameter.map((x)=>{
            retorno.push({id: x.id, pesquisa_avulsa: x.pesquisa_avulsa})
        })
        try {
          console.log(retorno)
            await api.post('UpdateParameter', retorno)

            history.push('/dashboard')
        } catch (error) {
            alert('Erro ao tentar atualizar as informações dos pastores')
        }
        

        /*
        await api.post('SendSurvey',{
            resposta: e.respota,
          }, {
            headers:{
              user_id: userId,
              survey_id: e.pergunta
            }
          })
        */
        
    }

    function Logout(){
        localStorage.clear()
        
        history.push('/')
    }
    function Cancelar(){        
        history.push('/dashboard')
    }

    function myFunction() {
      var input, filter, table, tr, td, i, txtValue;
      input = document.getElementById("myInput");
      filter = input.value.toUpperCase();
      table = document.getElementById("myTable");
      tr = table.getElementsByTagName("tr");
      for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[1];
        if (td) {
          txtValue = td.textContent || td.innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            tr[i].style.display = "";
          } else {
            tr[i].style.display = "none";
          }
        }       
      }
    }

    function filterTable() {
      // Variables
      let dropdown, table, rows, cells, country, filter;
      dropdown = document.getElementById("countriesDropdown");
      table = document.getElementById("myTable");
      rows = table.getElementsByTagName("tr");
      filter = dropdown.value;

      console.log(rows)
      console.log(filter)
    
      // Loops through rows and hides those with countries that don't match the filter
      for (let row of rows) { // `for...of` loops through the NodeList
        cells = row.getElementsByTagName("td");
        console.log(cells)
        country = cells[0] || null; // gets the 2nd `td` or nothing
        console.log(country)
        // if the filter is set to 'All', or this is the header row, or 2nd `td` text matches filter
        if (filter === "All" || !country || (filter === country.textContent)) {
          row.style.display = ""; // shows this row
        }
        else {
          row.style.display = "none"; // hides this row
        }
      }
    }

    return (

        <div className="parameter-container">
            <header>
                <img src={logo} alt="Igreja Metodista" />
                <span>Olá {userNome}</span>
                <button onClick={Logout} type="button">
                    <PowerSettingsNewIcon className="power-icon" />    
                </button>
            </header>
            <div className="parameter-content">

                <div className="parameter-table">
                
                    <form onSubmit={handleParameter}>
                    
                    <table id="myTable">
                            <thead>
                                <tr>
                                  <th>
                                    <select id="countriesDropdown" onChange={filterTable}>
                                      <option>All</option>
                                      <option>1ª</option>
                                      <option>2ª</option>
                                      <option>3ª</option>
                                      <option>4ª</option>
                                      <option>5ª</option>
                                      <option>6ª</option>
                                      <option>7ª</option>
                                      <option>8ª</option>
                                      <option>REMNE</option>
                                      <option>REMA</option>
                                    </select>
                                  </th>
                                  <th>
                                    <input type="text" id="myInput" onKeyUp={myFunction} placeholder="Procure pelo nome..." title="Digite um nome" /> 
                                  </th>
                                </tr>
                                <tr>
                                    <th>Região</th>
                                    <th className="regiao-classe">Lista de Pastores</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    parameter.map((e) => (
                                        <tr key={e.id}>
                                            <td className="name-classe">{e.regiao}</td>
                                            <td className="name-classe">{e.nome}</td>
                                            <td className="teste"><Checkbox style={{ width: 36, height: 36 }} id={e.id} value={e.pesquisa_avulsa} defaultChecked={e.pesquisa_avulsa} onChange={x=>checkChange(x.target)}></Checkbox></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <div className="footer-button">
                          <h3>Desaja confirmar as alterações?</h3>
                          <button className="btn" type="submit">Confirmar</button>
                          <button className="btn-cancelar" type="button" onClick={Cancelar}>Cancelar</button>
                        </div>
                    </form>
                    
                </div>
            </div>
        </div>
    )
}