import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import Logon from './pages/Logon'
import Survey from './pages/Survey'
import Survey2 from './pages/SurveyExibicao'
import Parameters from './pages/Parameters'
import ResponseSurvey from './pages/ResponseSurvey'
import SurveySeparate from './pages/SurveySeparate'
import Dashboard from './pages/Dashboard'


export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Logon} />
                <Route path="/dashboard" exact component={Dashboard} />
                <Route path="/parametros" exact component={Parameters} />
                <Route path="/pesquisa" exact component={Survey} />
                <Route path="/pesquisa2" exact component={Survey2} />
                <Route path="/pesquisa/avulsa" exact component={SurveySeparate} />
                <Route path="/pesquisa/respondida" exact component={ResponseSurvey} />
            </Switch>
        </BrowserRouter>
    )
}