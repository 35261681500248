import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import '../../Global.css'

import logo from '../../assets/logo.png'

import api from '../../services/api'
require('dotenv').config()

export default function App() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const history = useHistory()

  async function Logon(e){
    e.preventDefault()
    var dataAtual = new Date()
    var data = new Date(dataAtual.getFullYear(), dataAtual.getMonth(),dataAtual.getDate())
    let dataInicio = new Date(process.env.REACT_APP_DATAINICIO)
    let dataTermino = new Date(process.env.REACT_APP_DATATERMINO)

    console.log(data)
    console.log(dataInicio)
    console.log(dataTermino)

    localStorage.clear()
    try{
      const response = await api.post('Session', {
        email,
        senha: password
      })
      
      if (response.data.ativo) {
        localStorage.setItem('userId', response.data._id)
        localStorage.setItem('userNome', response.data.nome)
        localStorage.setItem('userRegiao', response.data.regiao)
        localStorage.setItem('userClasseAcesso', response.data.classe_acesso)
        localStorage.setItem('userNivelAcesso', response.data.nivel_acesso)
        localStorage.setItem('userPesquisaAvulsa', response.data.pesquisa_avulsa)
        console.log(response.data.pesquisa_avulsa)
        

        if (data >= dataInicio && data <= dataTermino){

            switch (response.data.nivel_acesso) {
              case 1:
                console.log("Acesso 1")
                break;
              case 2:
                console.log("Acesso 2")
                history.push('/dashboard')
                break;
              case 3:
                console.log("Acesso 3")
                try {
                  console.log(response.data._id)
                  const UserSurveyResponse = await api.post('UserSurvey', {
                    user_id: response.data._id
                  })
                  console.log(UserSurveyResponse)
                  if (UserSurveyResponse.data.length == 0){
                      history.push('/pesquisa')
                  } else {
                    history.push('/pesquisa/respondida')
                  }
                } catch (error) {
                  console.log(error)
                }
                break;
              default:
                break;
            }
        } else {
          alert('O período da pesquisa ainda não iniciou, por favor volte mais tarde.')  
        }
      } else {
        alert('Usuário inativo, favor contatar o administrador.')        
      }
    }catch (err) {
      console.log(err)
      alert('Falha de login, tente novamente.')
    }
  }

  return (
    <div className="container">
      <img src={logo} alt="Igreja Metodista" />
      <div className="content">
        <p>Faça o login para realizar a pesquisa.</p>

        <form onSubmit={Logon}>
          <label htmlFor="email">E-Mail</label>
          <input id="email"
            value={email}
            onChange={e=>setEmail(e.target.value)}
          type="email" placeholder="E-mail" />
          <label htmlFor="senha">Senha</label>
          <input id="senha"
            value={password}
            onChange={e=>setPassword(e.target.value)}
          type="password" placeholder="Senha" />

          <button className="btn" type="submit">Entrar</button>
        </form>

      </div>
    </div>
  );
}

